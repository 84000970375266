import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useInputVale from '../../hooks/useInputValue';

import InputForm from '../../components/common/input/inputForm';
import Layout from '../../components/layout';
import ModalContainer from '../../components/common/modalContainer/modalContainer';

import { sendContactEmail } from '../../utils';

import emailIcon from '../../images/email.png';
import mapIcon from '../../images/maps.png';
import phoneIcon from '../../images/phone.png';
import './contact.css';

const ContactPage = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [name, onChangeName] = useInputVale();
  const [email, onChangeEmail] = useInputVale();
  const [phone, onChangePhone] = useInputVale();
  const [bussines, onChangeBussines] = useInputVale();
  const [state, onChangeState] = useInputVale();
  const [city, onChangeCity] = useInputVale();
  const [job, onChangeJob] = useInputVale();
  const [message, onChangeMessage] = useInputVale();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function onSubmit(event) {
    event.preventDefault();

    if (loading) {
      return;
    }

    setLoading(true);

    const response = await sendContactEmail({
      city,
      company: bussines,
      description: message,
      email,
      name,
      phone,
      service: job,
      state,
    });

    if (response === 'OK') {
      setShowModal(true);
    } else {
      setShowModalError(true);
    }

    setLoading(false);

    // const response = await sendQuotingEmail({
    //   city,
    //   company: bussines,
    //   description: message,
    //   email,
    //   name,
    //   phone,
    //   service: job,
    //   state,
    // });

    // if (response.ok) {
    //   setShowModal(true);
    // } else {
    //   setShowModalError(true);
    // }

    setLoading(false);
  }

  function closeModal() {
    setShowModal(false);
  }

  function closeModalError() {
    setShowModalError(false);
  }

  return (
    <>
      <ModalContainer
        handleOutClick={closeModal}
        show={showModal}
        subTitle={t('contact.modal.subTitle')}
        title={t('contact.modal.title')}
      />

      <ModalContainer
        handleOutClick={closeModalError}
        show={showModalError}
        subTitle={t('contact.modalError.subTitle')}
        title={t('contact.modalError.title')}
      />

      <Layout whiteTheme>
        <>
          <div className="banner-contact banner-size">
            <div className="banner-contact-div">
              <h1 className="title">{t('contact.title')}</h1>

              <span>{t('contact.needHelp')}</span>

              <span>{t('contact.needHelpSubtitle')}</span>
            </div>
          </div>

          <div className="container flex col center">
            <div className="flex col contact-text-title">
              <h2 className="text-center">{t('contact.formTitle')}</h2>

              <span className="text-secondary text-center">{t('contact.formHit')}</span>

              <p className="contact-important text-center">{t('contact.formImportant')}</p>
            </div>

            <div className="row contacts-div">
              <div className="flex col-3">
                <img alt="email" src={emailIcon} />

                <a alt="" className="text-secondary" href="mailto:hola@eboxcode.com">hola@eboxcode.com</a>
              </div>

              <div className="flex col-3">
                <img alt="email" src={mapIcon} />

                <a alt="" className="text-secondary" href="https://www.google.com/maps/place/Edificio+Chapultepec,+Calle+Josefa+Ortiz+de+Dom%C3%ADnguez+598,+Chapultepec,+80040+Culiac%C3%A1n+Rosales,+Sin">
                  {t('contact.dir')}
                </a>
              </div>

              <div className="flex col-3">
                <img alt="email" src={phoneIcon} />

                <a alt="" className="text-secondary" href="tel:667-764-6261">(+52) 667 764 6261</a>
              </div>
            </div>

            <form id="contact" onSubmit={onSubmit}>
              <div className="row">
                <InputForm id="name" disabled={loading} requeried label={`${t('contact.name')}*`} value={name} onChange={onChangeName} />

                <InputForm id="email" disabled={loading} type="email" requeried label={`${t('contact.email')}*`} value={email} onChange={onChangeEmail} />

              </div>

              <div className="row">
                <InputForm id="phone" disabled={loading} type="number" requeried label={`${t('contact.phone')}*`} value={phone} onChange={onChangePhone} />

                <InputForm id="bussines" disabled={loading} label={`${t('contact.job')}*`} value={bussines} onChange={onChangeBussines} />
              </div>

              <div className="row">
                <InputForm id="state" disabled={loading} requeried label={`${t('contact.state')}*`} value={state} onChange={onChangeState} />

                <InputForm id="city" disabled={loading} requeried label={`${t('contact.city')}*`} value={city} onChange={onChangeCity} />
              </div>

              <div className="row">
                <div className="col col-sm-1 p-h-8 m-t-8">
                  <span>{`${t('contact.whatNeed')}*`}</span>

                  <select name="job" value={job} onChange={onChangeJob} disabled={loading}>
                    <option value="">{t('contact.jopSelect.choose')}</option>
                    <option value="App Móvil">{t('contact.jopSelect.option1')}</option>
                    <option value="Página Web">{t('contact.jopSelect.option2')}</option>
                    <option value="Plataforma Web">{t('contact.jopSelect.option3')}</option>
                    <option value="(Web+App)">{t('contact.jopSelect.option4')}</option>
                    <option value="eCommerce">{t('contact.jopSelect.option5')}</option>
                    <option value="Chatbot">{t('contact.jopSelect.option6')}</option>
                    <option value="Diseño Grafico">{t('contact.jopSelect.option7')}</option>
                    <option value="Otro">{t('contact.jopSelect.option8')}</option>
                  </select>
                </div>
              </div>

              <div>
                <label className="input-from p-h-8 m-t-8" htmlFor="message">
                  {`${t('contact.message')}*`}
                  <textarea id="message" disabled={loading} type="text" name="message" value={message} onChange={onChangeMessage} />
                </label>
              </div>

              <div className="flex center p-v-8">
                <button
                  className="btn btn-black"
                  disabled={loading}
                  type="submit"
                >
                  {loading ? <div className="loader" /> : t('contact.fromButton')}
                </button>
              </div>
            </form>
          </div>
        </>
      </Layout>
    </>
  );
};

export default ContactPage;
